import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Aneta = () => {
    return (
        <Layout>
            <Seo title="Aneta Kosmowska"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Aneta Kosmowska</h1>
                        <p className="member-intro_text_description">Certyfikowana instruktorka Pilatesu, artystka baletu, pedagog tańca
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src='../images/poza_aneta.jpg' 
                            placeholder="blurred"
                            loading="eager"
                            alt="instruktorka Aneta Kosmowska" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O ANECIE</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Certyfikowana instruktorka Pilatesu, pedagog tańca, artystka baletu Teatru Wielkiego w Łodzi, studentka amerykańskiej szkoły Pilatesu Balanced Body.
                            </h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Aneta pracuje metodą Pilates w nurcie współczesnym czyli sportowo - rehabilitacyjnym.</p>
                            <p className="member-hero-paragraph_info-text">Jest absolwentką Ogólnokształcącej Szkoły Baletowej w Warszawie i Uniwersytetu Muzycznego Fryderyka Chopina na kierunku taniec, specjalizacja pedagogika baletowa.</p>
                            <p className="member-hero-paragraph_info-text">Aneta ćwiczyła pilates jako uzupełnienie treningów baletowych, ale prawdziwa fascynacja metodą rozpoczęła się u niej podczas ciąży i po porodzie obserwując jak doskonale pomaga ona w przygotowaniu do porodu i powrocie do formy po tym wymagającym czasie.</p>
                            <p className="member-hero-paragraph_info-text">Jej ścieżka trenerska podąża w kierunku specjalizacji w pracy z kobietami w ciąży, przygotowującymi się do ciąży oraz kobietami po porodzie.</p>
                            <p className="member-hero-paragraph_info-text">W pracy z kobietami w okresie okołoporodowym skupia się na całościowym podejściu do osoby, biorąc pod uwagę zarówno możliwości fizyczne jak i emocjonalne.</p>
                            <p className="member-hero-paragraph_info-text">Oprócz pracy z kobietami w ciąży i po porodzie Aneta specjalizuje się w zajęciach Pilates dla tancerzy.</p>
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Polski/ Polish</li>
                            <li>Francuski/ French</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Pilates</li>
                            <li>Trening dla kobiet w ciąży</li>
                            <li>Trening mięśni dna miednicy</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Zajęcia indywidualne</li>
                                <li>Zajęcia w duetach</li>
                                <li>Warsztaty dla kobiet w ciąży</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Reformer</li>
                                <li>Reformer z Wieżą</li>
                                <li>Ladder Barrel</li>
                                <li>Cadillac</li>
                                <li>Wunda Chair, Combo Chair</li>
                                <li>Spine Corrector, Pilates Arc</li>
                                <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Kobiety przygotowujące się do ciąży</li>
                            <li>Kobiety w ciąży</li>
                            <li>Kobiety po porodzie</li>
                            <li>Osoby z wadami postawy</li>
                            <li>Tancerze</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_info_link-container">
                            <Link to="/zapisy" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</Link>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Aneta;